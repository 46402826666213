<template>
    <div class="row">
        <div class="col-12 col-md-6">
            <h3 class="mb-2">{{ $t('basicData.headings.basicInfo') }}</h3>
            <schema-form
                class="p-3 border rounded-sm mb-4"
                :input-schema="schema"
                :input-form-data="formData"
                :visible-groups="personalGroups"
                @submit="onSubmitSchemaForm"
            />

            <div v-if="formData.clientType !== 'PO'">
                <h3 class="mb-2">{{ $t('personalId.heading') }}</h3>
                <schema-form
                    class="p-3 border rounded-sm mb-4"
                    :input-schema="schema"
                    :input-form-data="formData"
                    :visible-groups="personalInfoGroups"
                    :validation-schema="personalInfoValidationSchema"
                    @submit="onSubmitSchemaForm"
                    @is-valid="onIsValid"
                />
            </div>
            <div v-if="formData.clientType === 'PO'">
                <h3 class="mb-2">{{ $t('basicData.headings.contactInfo') }}</h3>
                <schema-form
                    class="p-3 border rounded-sm mb-4"
                    :input-schema="schema"
                    :input-form-data="formData"
                    :visible-groups="contactGroups"
                    @submit="onSubmitSchemaForm"
                />
            </div>
        </div>
        <div class="col-12 col-md-6">
            <h3 class="mb-2">{{ $t('basicData.headings.address') }}</h3>
            <schema-form
                class="p-3 border rounded-sm mb-4"
                :input-schema="schema"
                :input-form-data="formData"
                :visible-groups="addressGroups"
                @submit="onSubmitSchemaForm"
            />
            <h3 class="mb-2">{{ $t('basicData.headings.postAddress') }}</h3>
            <schema-form
                class="p-3 border rounded-sm mb-4"
                :input-schema="schema"
                :input-form-data="formData"
                :visible-groups="postAddressGroups"
                @submit="onSubmitSchemaForm"
            />

            <h3 class="mb-2">{{ $t('basicData.headings.otherInfo') }}</h3>
            <schema-form
                class="p-3 border rounded-sm mb-4"
                :input-schema="schema"
                :input-form-data="formData"
                :visible-groups="otherGroups"
                @submit="onSubmitSchemaForm"
            />
            <div v-if="formData.clientType !== 'PO'">
                <h3 class="mb-2">{{ $t('basicData.headings.contactInfo') }}</h3>
                <schema-form
                    class="p-3 border rounded-sm"
                    :input-schema="schema"
                    :input-form-data="formData"
                    :visible-groups="contactGroups"
                    @submit="onSubmitSchemaForm"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators'

import SchemaForm from './SchemaForm.vue'

export default {
    name: 'BasicData',
    components: {
        SchemaForm
    },
    props: {
        schema: {
            type: Array,
            required: false,
            default: () => []
        },
        formData: {
            type: Object,
            required: false,
            default: () => ({})
        },
        editedFormData: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    computed: {
        personalGroups () {
            const base = 'basicData.*'
            let groups = [base]
            switch (this.formData.clientType) {
                case 'FO':
                    groups = [...groups, `!${base}company*`, `!${base}ic`, `!${base}vat`]
                    break
                case 'PO':
                    groups = [...groups, `!${base}title`, `!${base}name`, `!${base}surname`, `!${base}politician`, `!${base}noRc`, `!${base}birthDate`, `!${base}rc`]
                    break
                case 'OS':
                    groups = [...groups, `!${base}companyName`, `!${base}noRc`, `!${base}rc`]
                    break
            }
            return [...groups, '!basicData.address*', '!basicData.postAddress*', '!basicData.email', '!basicData.phone*', '!basicData.countryOrigin']
        },
        addressGroups () {
            return ['basicData.address*']
        },
        postAddressGroups () {
            return ['basicData.postAddress*', '!basicData.postAddressActive*']
        },
        contactGroups () {
            return ['basicData.phone', 'basicData.email']
        },
        otherGroups () {
            return ['basicData.countryOrigin']
        },
        personalInfoGroups () {
            return ['personalId.*', '!personal.secondaryDocumentType', '!personalId.uploads']
        },
        personalInfoValidationSchema () {
            return {
                editedFormData: {
                    'personalId.citizenship': {
                        required
                    },
                    'personalId.documentNumber': {
                        required
                    },
                    'personalId.documentIssuedBy': {
                        required
                    },
                    'personalId.documentValidity': {
                        required,
                        custom: function (value) {
                            const today = new Date()
                            today.setHours(0, 0, 0, 0)
                            if (value instanceof Date) {
                                return (value >= today)
                            } else {
                                try {
                                    const date = new Date(value)
                                    return (date >= today)
                                } catch (err) {
                                    return false
                                }
                            }
                        }
                    },
                    'personalId.sex': {
                        required
                    },
                    'personalId.birthCity': {
                        required
                    },
                    'personalId.documentType': {
                        required
                    }
                }
            }
        }
    },
    methods: {
        onSubmitSchemaForm (data) {
            this.$emit('submit', data)
        },
        onIsValid (isValid) {
            this.$emit('is-valid', isValid)
        }
    }
}
</script>
