<template>
    <div>
        <h3 class="mb-2">{{$t('clientSessions.detail.realOwner.heading')}}</h3>
        <real-owners-list
            :active-index="activeOwnerIndex"
            :people="realOwners"
            @edit="onEditOwner"
            @close="onCloseOwner"
        />
        <div v-if="showOwner" class="p-3 bg-cyrrus-gray-light mt-3 rounded-sm border">
            <div class="row">
                <div class="col-12 col-md-6">
                    <h4 class="mb-2">{{ $t('basicData.headings.basicInfo') }}</h4>
                    <schema-form
                        class="p-3 border bg-white rounded-sm shadow mb-4"
                        :input-schema="schema"
                        :input-form-data="formData"
                        :visible-groups="basicInfoGroups"
                        @submit="onSubmitSchemaForm"
                    />

                    <h4 class="mb-2">{{ $t('basicData.headings.otherInfo') }}</h4>
                    <schema-form
                        class="p-3 border bg-white rounded-sm shadow"
                        :input-schema="schema"
                        :input-form-data="formData"
                        :visible-groups="otherGroups"
                        @submit="onSubmitSchemaForm"
                    />
                </div>
                <div class="col-12 col-md-6">
                    <h4 class="mb-2">{{ $t('basicData.headings.address') }}</h4>
                    <schema-form
                        class="p-3 border bg-white rounded-sm shadow"
                        :input-schema="schema"
                        :input-form-data="formData"
                        :visible-groups="addressGroups"
                        @submit="onSubmitSchemaForm"
                    />
                </div>
            </div>
            <p class="mt-4">
                <b-btn
                    @click.prevent="onCloseOwner"
                    size="sm"
                >
                    {{ $t('app.cancel') }}
                </b-btn>
            </p>
        </div>
    </div>
</template>
<script>
import RealOwnersList from './RealOwnersList.vue'
import SchemaForm from './SchemaForm.vue'
import flat from 'flat'

export default {
    name: 'RealOwners',
    components: {
        RealOwnersList,
        SchemaForm
    },
    props: {
        schema: {
            type: Array,
            required: false,
            default: () => []
        },
        formData: {
            type: Object,
            required: false,
            default: () => ({})
        },
        editedFormData: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    data () {
        return {
            showOwner: false,
            owner: 'realOwners.*',
            activeOwnerIndex: null
        }
    },
    computed: {
        realOwners () {
            const mergedData = { ...this.formData, ...this.editedFormData }
            const unflatten = flat.unflatten(mergedData)
            return unflatten.realOwners
        },
        basicInfoGroups () {
            return [
                'name',
                'surname',
                'birthDate',
                'citizenship'
            ].map(item => `${this.owner}.${item}`)
        },
        addressGroups () {
            return [
                'addressStreet',
                'addressCity',
                'addressZip',
                'addressCountry'
            ].map(item => `${this.owner}.${item}`)
        },
        otherGroups () {
            return [
                'isRealOwner',
                'isStatutory',
                'statutoryType',
                'politician',
                'countryOrigin',
                'relationshipDetail'
            ].map(item => `${this.owner}.${item}`)
        }
    },
    methods: {
        onEditOwner ({ index }) {
            this.onCloseOwner()
            this.setOwner(index)
            this.$nextTick(() => {
                this.showOwner = true
            })
        },
        setOwner (index = 0) {
            this.activeOwnerIndex = index
            this.owner = `realOwners.${index}`
        },
        onCloseOwner () {
            this.activeOwnerIndex = null
            this.showOwner = false
        },
        onSubmitSchemaForm (data) {
            this.$emit('submit', data)
        }
    }
}
</script>
