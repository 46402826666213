<template>
    <b-form @submit.prevent="onSubmit">
        <component
            v-for="schema in filteredSchema"
            :key="schema.path"
            v-model="editedFormData[schema.path]"
            :is="getComponent(schema)"
            :label="$t(schema.label)"
            :title="$t(schema.label)"
            :content="getContent(schema, originalFormData)"
            :enable-compare="true"
            :compareContent="getContent(schema, editedFormData)"
            :locale="currentLang"
            :options="schema.options"
            :stacked="false"
            :validation="validationSchema ? $v.editedFormData[schema.path] : undefined"
            size="sm"
        />

        <p
            v-if="showForm"
            class="mt-4 mb-0"
        >
            <b-btn
                class="mr-2"
                type="submit"
                variant="primary"
                size="sm"
            >
                {{ $t('app.submit') }}
            </b-btn>
            <b-btn
                class="mr-2"
                @click.prevent="onCancel"
                variant="secondary"
                size="sm"
            >
                {{ $t('app.cancel') }}
            </b-btn>
            <b-btn
                @click.prevent="onReset"
                variant="danger"
                size="sm"
            >
                {{ $t('app.reset') }}
            </b-btn>
        </p>
        <p
            v-else
            class="mt-3 mb-0"
        >
            <b-btn
                class="mr-2"
                @click.prevent="onEdit"
                variant="primary"
                size="sm"
            >
                {{ $t('app.edit') }}
            </b-btn>
            <slot name="footerButton" />
        </p>
    </b-form>
</template>
<script>
import micromatch from 'micromatch'

export default {
    name: 'SchemaForm',
    props: {
        inputSchema: {
            type: Array,
            required: false,
            default: () => []
        },
        inputFormData: {
            type: Object,
            required: false,
            default: () => ({})
        },
        visibleGroups: {
            type: Array,
            required: false,
            default: () => ['*']
        },
        validationSchema: {
            type: Object,
            required: false,
            default: null
        }
    },
    data () {
        return {
            showForm: false,
            editedFormData: { ...(this.originalFormData || {}) },
            unsavedFormData: { ...(this.originalFormData || {}) }
        }
    },
    computed: {
        filteredSchema () {
            const paths = this.inputSchema.map(item => item.path)
            const matched = micromatch(paths, this.visibleGroups)
            return this.inputSchema.filter(item => matched.includes(item.path))
        },
        originalFormData () {
            const matched = micromatch(Object.keys(this.inputFormData), this.visibleGroups)
            const tmp = {}
            for (const key of matched) {
                tmp[key] = this.inputFormData[key]
            }
            return tmp
        },
        currentLang () {
            return ((this.$store.state.lang === 'cz') ? 'cs-CZ' : 'en-US')
        }
    },
    created () {
        this.getEditedFormData()
        this.getUnsavedFormData()
    },
    mounted () {
        this.$nextTick(() => {
            if (this.$v) {
                this.$v.$reset()
                this.$emit('is-valid', !this.$v.$invalid)
                if (this.$v.$invalid) {
                    this.onEdit()
                    this.$v.$touch()
                }
            }
        })
    },
    methods: {
        getEditedFormData () {
            const tmp = {}
            for (const key of Object.keys(this.originalFormData)) {
                tmp[`${key}`] = window.sessionStorage.getItem(`cfxContractsApp.${key}`) || this.originalFormData[`${key}`]
            }
            this.editedFormData = { ...tmp }
        },
        getUnsavedFormData () {
            this.unsavedFormData = { ...this.editedFormData }
        },
        getContent (schema, formData) {
            if (schema.inputType === 'date') {
                return (new Date(formData[schema.path])).toLocaleDateString(this.currentLang)
            }
            if (`${formData[schema.path]}` === 'true' || `${formData[schema.path]}` === 'false') {
                return this.$t(`app.${formData[schema.path] ? 'yes' : 'no'}`)
            }
            if (schema.options) {
                const foundOption = schema.options.find(item => `${item.value}` === `${formData[schema.path]}`)
                return (foundOption && foundOption.text) ? foundOption.text : formData[schema.path]
            }
            return formData[schema.path]
        },
        getComponent (schema) {
            if (!this.showForm) {
                return 'detail-item'
            }
            switch (schema.inputType) {
                case 'text':
                    return 'form-input'
                case 'date':
                    return 'form-input-date-picker'
                case 'checkbox':
                    return 'form-input-checkbox'
                case 'select':
                    return 'form-input-select'
                case 'radio':
                    return 'form-input-radio-group'
            }
        },
        onSubmit () {
            this.$v.$reset()

            if (this.$v.$invalid) {
                this.$v.$touch()
                return
            }
            const data = { ...this.editedFormData }
            for (const key of Object.keys(this.originalFormData)) {
                window.sessionStorage.setItem(`cfxContractsApp.${key}`, this.editedFormData[`${key}`] || '')
            }
            this.unsavedFormData = data
            this.$emit('is-valid', !this.$v.$invalid)
            this.$emit('submit', data)
            this.$nextTick(() => {
                this.showForm = false
            })
        },
        onCancel () {
            this.editedFormData = { ...this.unsavedFormData }
            this.showForm = false
        },
        onEdit () {
            this.showForm = true
        },
        onReset () {
            this.editedFormData = { ...this.originalFormData }
        }
    },
    validations () {
        return this.validationSchema ?? {}
    }
}
</script>
