<template>
    <div>
        <h3 class="mb-2">{{$t('clientSessions.detail.tab.people')}}</h3>
        <company-people-list
            :active-index="activePersonIndex"
            :people="companyPeople"
            @edit="onEditPerson"
            @close="onClosePerson"
        />
        <div v-if="showPerson" class="p-3 bg-cyrrus-gray-light mt-3 rounded-sm border">
            <div class="row my-2">
                <div class="col-12 col-md-6">
                    <h4 class="mb-2">{{ $t('basicData.headings.basicInfo') }}</h4>
                    <schema-form
                        class="p-3 border bg-white rounded-sm shadow mb-4"
                        :input-schema="schema"
                        :input-form-data="formData"
                        :visible-groups="basicInfoGroups"
                        @submit="onSubmitSchemaForm"
                    />
                    <h4 class="mb-2">{{ $t('personalId.heading') }}</h4>
                    <schema-form
                        class="p-3 border bg-white rounded-sm shadow"
                        :input-schema="schema"
                        :input-form-data="formData"
                        :visible-groups="personalIdGroups"
                        :validation-schema="personalIdValidationSchema"
                        @submit="onSubmitSchemaForm"
                        @is-valid="onIsValid"
                    />
                </div>
                <div class="col-12 col-md-6">
                    <h4 class="mb-2">{{ $t('basicData.headings.address') }}</h4>
                    <schema-form
                        class="p-3 border bg-white rounded-sm shadow mb-4"
                        :input-schema="schema"
                        :input-form-data="formData"
                        :visible-groups="addressGroups"
                        @submit="onSubmitSchemaForm"
                    />
                    <h4 class="mb-2">{{ $t('basicData.headings.contactInfo') }}</h4>
                    <schema-form
                        class="p-3 border bg-white rounded-sm shadow mb-4"
                        :input-schema="schema"
                        :input-form-data="formData"
                        :visible-groups="contractGroups"
                        @submit="onSubmitSchemaForm"
                    />
                    <h4 class="mb-2">{{ $t('basicData.headings.otherInfo') }}</h4>
                    <schema-form
                        class="p-3 border bg-white rounded-sm shadow"
                        :input-schema="schema"
                        :input-form-data="formData"
                        :visible-groups="otherGroups"
                        @submit="onSubmitSchemaForm"
                    />
                </div>
            </div>
            <p class="mt-4">
                <b-btn
                    @click.prevent="onClosePerson"
                    size="sm"
                    class="mr-2"
                >
                    {{ $t('app.cancel') }}
                </b-btn>
            </p>
        </div>
    </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import flat from 'flat'

import CompanyPeopleList from './CompanyPeopleList.vue'
import SchemaForm from './SchemaForm.vue'

export default {
    name: 'CompanyPeople',
    components: {
        CompanyPeopleList,
        SchemaForm
    },
    props: {
        schema: {
            type: Array,
            required: false,
            default: () => []
        },
        formData: {
            type: Object,
            required: false,
            default: () => ({})
        },
        editedFormData: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    data () {
        return {
            showPerson: false,
            companyPerson: 'companyPeople.*',
            activePersonIndex: null
        }
    },
    computed: {
        companyPeople () {
            const mergedData = { ...this.formData, ...this.editedFormData }
            const unflatten = flat.unflatten(mergedData)
            return unflatten.companyPeople
        },
        basicInfoGroups () {
            return [
                'titleBefore',
                'titleAfter',
                'name',
                'surname',
                'noRc',
                'rc',
                'birthDate'
            ].map(item => `${this.companyPerson}.${item}`)
        },
        addressGroups () {
            return [
                'addressStreet',
                'addressCity',
                'addressZip',
                'addressCountry'
            ].map(item => `${this.companyPerson}.${item}`)
        },
        personalIdGroups () {
            return [
                'birthCity',
                'citizenship',
                'documentNumber',
                'documentValidity',
                'documentIssuedBy',
                'documentType',
                'sex'
            ].map(item => `${this.companyPerson}.${item}`)
        },
        contractGroups () {
            return [
                'phone',
                'email'
            ].map(item => `${this.companyPerson}.${item}`)
        },
        otherGroups () {
            return [
                'jobFunction',
                'politician',
                'executive',
                'broker',
                'countryOrigin'
            ].map(item => `${this.companyPerson}.${item}`)
        },
        personalIdValidationSchema () {
            const base = `companyPeople.${this.activePersonIndex}`
            const data = {
                editedFormData: {
                    citizenship: {
                        required
                    },
                    documentNumber: {
                        required
                    },
                    documentIssuedBy: {
                        required
                    },
                    documentValidity: {
                        required,
                        custom: function (value) {
                            const today = new Date()
                            today.setHours(0, 0, 0, 0)
                            if (value instanceof Date) {
                                return (value >= today)
                            } else {
                                try {
                                    const date = new Date(value)
                                    return (date >= today)
                                } catch (err) {
                                    return false
                                }
                            }
                        }
                    },
                    sex: {
                        required
                    },
                    birthCity: {
                        required
                    },
                    documentType: {
                        required
                    }
                }
            }
            const result = {}
            for (const key of Object.keys(data.editedFormData)) {
                result[`${base}.${key}`] = data.editedFormData[key]
            }
            return {
                editedFormData: {
                    ...result
                }
            }
        }
    },
    methods: {
        onEditPerson ({ index }) {
            this.onClosePerson()
            this.setPerson(index)
            this.$nextTick(() => {
                this.showPerson = true
            })
        },
        setPerson (index = 0) {
            this.activePersonIndex = index
            this.companyPerson = `companyPeople.${index}`
        },
        onClosePerson () {
            this.activePersonIndex = null
            this.showPerson = false
        },
        onSubmitSchemaForm (data) {
            this.$emit('submit', data)
        },
        onIsValid (isValid) {
            this.$emit('is-valid', isValid)
        }
    }
}
</script>
