<template>
    <div class="company-persons list-group bg-white">
        <div class="list-group-item"
            v-for="(person, index) in people"
            :key="person._id || person.ivanId || index"
        >
            <div class="row align-content-center align-items-center">
                <div class="col-12 col-md-9 col-lg-10">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="font-weight-bold text-primary">{{ $t('realOwners.list.fullname') }}</div>
                            {{ person.name }} {{ person.surname }}
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="font-weight-bold text-primary">{{ $t('realOwners.list.rc') }}</div>
                            <span>{{ person.birthDate ? new Date(person.birthDate).toLocaleDateString() : person.birthDate }}</span>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="font-weight-bold text-primary">{{ $t('realOwners.list.address') }}</div>
                            {{ person.addressStreet }}, {{ person.addressZip }} {{ person.addressCity }}
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12 col-md-4">
                            <div class="font-weight-bold text-primary">{{ $t('realOwners.list.isRealOwner') }}</div>
                            {{ yesOrNoOption(person.isRealOwner) }}
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="font-weight-bold text-primary">{{ $t('realOwners.list.isStatutory') }}</div>
                            {{ yesOrNoOption(person.isStatutory) }}
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="font-weight-bold text-primary">{{ $t('realOwners.list.relationshipDetail') }}</div>
                            {{ person.relationshipDetail }}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3 col-lg-2 text-md-right whitespace-nowrap mt-3 mt-lg-0">
                    <b-btn
                        :title="$t('realOwners.list.edit')"
                        variant="primary"
                        size="sm"
                        @click.prevent="onEdit(person, index)"
                    >
                        {{ $t('app.detail') }}
                    </b-btn>
                    <b-btn
                        v-if="index === activeIndex"
                        :title="$t('realOwners.list.close')"
                        class="ml-2"
                        size="sm"
                        @click.prevent="onClose"
                    >
                        {{ $t('app.close') }}
                    </b-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'RealOwnersList',
    props: {
        people: {
            type: Array,
            required: false,
            default: () => []
        },
        activeIndex: {
            type: Number,
            required: false,
            default: null
        }
    },
    methods: {
        onEdit (person, index) {
            this.$emit('edit', { person, index })
        },
        onClose () {
            this.$emit('close')
        },
        yesOrNoOption (value) {
            return value ? this.$t('app.yes') : this.$t('app.no')
        }
    }
}
</script>
