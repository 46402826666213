<template>
    <div class="my-4">
        <component-loader v-if="enumsLoading || clientDataLoading"/>
        <div v-else>
            <h1>{{ $t('dataCheckup.heading') }}</h1>
            <b-form @submit.prevent="onSubmit" class="bg-white shadow p-4 rounded">
                <basic-data
                    :form-data="formData"
                    :schema="schema.schema"
                    :edited-form-data="editedFormData"
                    @submit="onSubmitSchemaForm"
                    @is-valid="onBasicDataValid"
                />
                <company-people
                    v-if="clientData.companyPeople && Array.isArray(clientData.companyPeople) && clientData.companyPeople.length > 0"
                    class="mt-4"
                    ref="companyPeopleRef"
                    :form-data="formData"
                    :schema="schema.schema"
                    :edited-form-data="editedFormData"
                    @submit="onSubmitSchemaForm"
                    @is-valid="onCompanyPeopleValid"
                />
                <real-owners
                    v-if="clientData.realOwners && Array.isArray(clientData.realOwners) && clientData.realOwners.length > 0"
                    class="mt-4"
                    :form-data="formData"
                    :schema="schema.schema"
                    :edited-form-data="editedFormData"
                    @submit="onSubmitSchemaForm"
                />
                <div class="mt-4">
                    <div v-if="!showUploader">
                        <p class="alert alert-primary">
                            {{ $t('dataCheckup.uploadNewFiles') }}
                        </p>
                        <p>
                            <b-btn
                                variant="primary"
                                size="sm"
                                @click.prevent="onShowUploader"
                            >
                                {{ $t('dataCheckup.uploadNewFilesButton') }}
                            </b-btn>
                        </p>
                    </div>
                    <div v-if="showUploader" class="p-3 bg-cyrrus-gray-light border rounded-sm">
                        <uploader
                            :uploads="uploads"
                            :loading-uploads="loadingUploads"
                            :show-uploader="showUploader"
                            :disable-cancel-button="shouldUploadNewFiles"
                            @remove-upload="onUploadRemove"
                            @load-uploads="loadUploads"
                            @close="onCloseUploader"
                            @is-valid="onFormValid"
                        />
                    </div>
                </div>
                <div class="mt-4">
                    <div v-if="!showOtherFilesUploader">
                        <p class="alert alert-primary">
                            {{ $t('dataCheckup.uploadNewOtherFilesAlert') }}
                        </p>
                        <p>
                            <b-btn
                                variant="primary"
                                size="sm"
                                @click.prevent="onShowOtherFilesUploader"
                            >
                                {{ $t('dataCheckup.uploadNewOtherFiles') }}
                            </b-btn>
                        </p>
                    </div>
                    <div v-else class="p-3 bg-cyrrus-gray-light border rounded-sm">
                        <other-files-uploader
                            :uploads="otherFilesUploads"
                            :loading-uploads="loadingUploads"
                            @remove-upload="onUploadRemove"
                            @load-uploads="loadUploads"
                            @close="onCloseOtherFilesUploader"
                        />
                    </div>
                </div>
                <form-input-textarea
                    class="mt-4"
                    :label="$t('dataCheckup.otherChanges')"
                    v-model="otherChangesComputed"
                    :placeholder="$t('dataCheckup.otherChangesPlaceholder')"
                />
                <p class="mt-5">
                    <b-btn
                        type="submit"
                        variant="primary"
                        :disabled="!canSubmit"
                    >
                        {{ $t('app.submit') }}
                    </b-btn>
                </p>
            </b-form>
        </div>
    </div>
</template>
<script>
import flat from 'flat'
import { mapGetters } from 'vuex'
import { Schema } from '../Components/Schema'
import BasicData from '../Components/BasicData.vue'
import CompanyPeople from '../Components/CompanyPeople.vue'
import RealOwners from '../Components/RealOwners.vue'
import Uploader from '../Components/Uploader.vue'
import OtherFilesUploader from '../Components/OtherFilesUploader.vue'
import micromatch from 'micromatch'
import { OTHER_FILES_ORIGIN_TYPE, PERSONAL_ID_ORIGIN_TYPE } from '../Components/UploadOriginType'

let tm

export default {
    name: 'DataCheckup',
    components: {
        BasicData,
        CompanyPeople,
        RealOwners,
        Uploader,
        OtherFilesUploader
    },
    data () {
        return {
            clientData: null,
            editedFormData: null,
            enumsLoading: true,
            clientDataLoading: true,
            showUploader: false,
            showOtherFilesUploader: false,
            otherChanges: window.sessionStorage.getItem('cfxContractsApp.otherChanges') || '',
            isUploadValid: true,
            isBasicDataValid: true,
            isCompanyPeopleValid: true,
            loadingUploads: true,
            uploads: [],
            otherFilesUploads: []
        }
    },
    computed: {
        ...mapGetters([
            'countries',
            'personalIdTypes',
            'genders',
            'riskActivities',
            'statutoryTypes'
        ]),
        canSubmit () {
            return this.isUploadValid && this.isBasicDataValid && this.isCompanyPeopleValid
        },
        clientType () {
            return this.clientData?.clientType || ''
        },
        otherChangesComputed: {
            get () {
                return this.otherChanges
            },
            set (value) {
                clearTimeout(tm)
                tm = setTimeout(() => { window.sessionStorage.setItem('cfxContractsApp.otherChanges', value) }, 500)
                this.otherChanges = value
            }
        },
        shouldUploadNewFiles () {
            if (!this.editedFormData || Object.keys(this.editedFormData).length === 0) {
                return false
            }
            const prefixesToTriggerUploader = [
                'companyPeople.*.name',
                'companyPeople.*.surname',
                'companyPeople.*.address*',
                'companyPeople.*.document*'
            ]
            if (['FO', 'OS'].includes(this.clientType)) {
                prefixesToTriggerUploader.push(...[
                    'basicData.name',
                    'basicData.surnama',
                    'basicData.address*',
                    'personalId.*'
                ])
            }

            const matched = micromatch(Object.keys(this.formData), prefixesToTriggerUploader)

            let shouldUploadNewFiles = false
            for (const match of matched) {
                if (this.editedFormData[match] === undefined) {
                    continue
                }
                if (this.formData[match] !== this.editedFormData[match]) {
                    shouldUploadNewFiles = true
                    break
                }
            }
            return shouldUploadNewFiles
        },
        schema () {
            return new Schema(
                this.clientData,
                {
                    countries: this.countries,
                    personalIdTypes: this.personalIdTypes,
                    genders: this.genders,
                    riskActivities: this.riskActivities,
                    statutoryTypes: this.statutoryTypes
                }
            )
        },
        formData () {
            return flat.flatten(this.clientData)
        }
    },
    mounted () {
        this.loadEnums()
        this.loadData()
            .then(() => {
                this.$nextTick(() => {
                    this.getEditedFormData()
                    this.onCheckCompanyPeopleDocumentValidity()
                    this.loadUploads()
                })
            })
    },
    watch: {
        shouldUploadNewFiles (value) {
            this.$nextTick(() => {
                if (this.showUploader) {
                    return
                }
                this.showUploader = value
            })
        }
    },
    methods: {
        onCompanyPeopleValid (isValid) {
            this.isCompanyPeopleValid = isValid
        },
        onBasicDataValid (isValid) {
            this.isBasicDataValid = isValid
        },
        onFormValid (isValid) {
            this.isUploadValid = isValid
        },
        onCheckCompanyPeopleDocumentValidity () {
            for (const [index, companyPerson] of (this.clientData?.companyPeople ?? []).entries()) {
                if (new Date(companyPerson.documentValidity) < new Date()) {
                    this.isCompanyPeopleValid = false
                    this.$refs.companyPeopleRef.onEditPerson(index)
                }
            }
        },
        getEditedFormData () {
            const tmp = {}
            for (const key of Object.keys(this.formData)) {
                const sessionStorageValue = window.sessionStorage.getItem(`cfxContractsApp.${key}`)
                if (sessionStorageValue) {
                    tmp[`${key}`] = sessionStorageValue
                }
            }
            this.editedFormData = { ...tmp }
        },
        async loadUploads () {
            this.loadingUploads = true
            try {
                const { data } = await this.$api.dataCheckup.listUploadedFiles()
                this.otherFilesUploads = data.filter(item => item.fileCustomData?.origin === OTHER_FILES_ORIGIN_TYPE)
                this.uploads = data.filter(item => item.fileCustomData?.origin === PERSONAL_ID_ORIGIN_TYPE || item.fileCustomData === null)

                if (this.uploads.length > 0 && this.showUploader === false) {
                    this.showUploader = true
                }
                if (this.otherFilesUploads.length > 0 && this.showOtherFilesUploader === false) {
                    this.showOtherFilesUploader = true
                }
            } catch (error) {
                console.error(error)
            } finally {
                this.$nextTick(() => {
                    this.loadingUploads = false
                })
            }
        },
        async onUploadRemove (uploadId) {
            try {
                await this.$api.dataCheckup.deleteUploadedFile(uploadId)
                this.loadUploads()
            } catch (error) {
                console.error(error)
            }
        },
        async loadData () {
            try {
                this.clientDataLoading = true
                const response = await this.$api.dataCheckup.read()
                this.clientData = response.data
            } catch (error) {
                console.error(error)
                this.$notify.error('Nepodařilo se načíst sezení.')
            } finally {
                this.$nextTick(() => {
                    this.clientDataLoading = false
                })
            }
        },
        loadEnums () {
            const enums = [
                { name: 'countries', id: 'countries' },
                { name: 'personalIdTypes', id: 'personal-id-types' },
                { name: 'genders', id: 'genders' },
                { name: 'riskActivities', id: 'risk-activities' },
                { name: 'statutoryTypes', id: 'statutory-types' }
            ]
            this.enumsLoading = true
            Promise.all(enums.map(async (item) => {
                const response = await this.$api.enums.read(item.id)
                return {
                    enum: item.name,
                    content: [...response.data]
                }
            })).then((lists) => {
                for (const list of lists) {
                    this.$store.commit('setEnum', list)
                }
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                this.$nextTick(() => {
                    this.enumsLoading = false
                })
            })
        },
        onSubmitSchemaForm (data) {
            this.editedFormData = { ...this.editedFormData, ...data }
        },
        onShowUploader () {
            this.showUploader = true
        },
        onCloseUploader () {
            this.showUploader = false
        },
        onShowOtherFilesUploader () {
            this.showOtherFilesUploader = true
        },
        onCloseOtherFilesUploader () {
            this.showOtherFilesUploader = false
        },
        async onSubmit () {
            const changedClientData = []
            for (const key of Object.keys(this.editedFormData)) {
                if (`${this.editedFormData[key]}` !== `${this.formData[key]}`) {
                    const foundSchema = this.schema.schema.find(item => item.path === key)
                    const label = foundSchema && foundSchema.label ? foundSchema.label : key
                    changedClientData.push({ key, newValue: this.editedFormData[key], friendlyName: this.$t(label, { locale: 'cs' }) })
                }
            }
            const updatedData = {
                changedClientData,
                otherChanges: this.otherChanges
            }
            try {
                await this.$api.dataCheckup.sendUpdateData(updatedData)
                window.sessionStorage.clear()
                this.$nextTick(() => {
                    this.$router.replace({
                        name: 'DataCheckupSent'
                    })
                })
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('dataCheckup.cannotSendData'))
            }
        }
    }
}
</script>
