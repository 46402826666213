<template>
    <div class="mb-2">
        <h4 class="mb-2">{{ $t('otherFilesUploader.heading') }}</h4>
        <div class="bg-white p-3 rounded-sm shadow">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <strong>{{ $t('personalId.chooseFiles')}}</strong>
                    <uploader-box
                        :multi="true"
                        :url="`${$api.dataCheckup.uploaderUrl}?origin=${OTHER_FILES_ORIGIN_TYPE}`"
                        class="mt-3"
                        @uploaded="onUploadUploaded"
                    />
                </div>
                <div class="col-12 col-lg-6" v-if="uploads.length > 0">
                    <strong>{{ $t('personalId.listUploadedHeading') }}</strong>
                    <loader-overlay
                        :loading="loadingUploads"
                        class="mt-3"
                    >
                        <uploads-list
                            :uploads="uploads"
                            :removed="[]"
                            @remove="onUploadRemove"
                        />
                    </loader-overlay>
                </div>
            </div>
            <p class="mt-4 mb-0">
                <b-btn
                    @click.prevent="onCloseUploader"
                    size="sm"
                    class="mr-2"
                >
                    {{ $t('app.cancel') }}
                </b-btn>
            </p>
        </div>
    </div>
</template>
<script>
import UploaderBox from '../../../../../Components/UploaderBox.vue'
import UploadsList from '../../../../../Components/UploadsList.vue'
import { OTHER_FILES_ORIGIN_TYPE } from './UploadOriginType'

export default {
    name: 'Uploader',
    components: {
        UploaderBox,
        UploadsList
    },
    props: {
        showUploader: {
            type: Boolean,
            required: true
        },
        uploads: {
            type: Array,
            required: false,
            default: () => []
        },
        loadingUploads: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.loadUploads()
        })
    },
    computed: {
        OTHER_FILES_ORIGIN_TYPE () {
            return OTHER_FILES_ORIGIN_TYPE
        }
    },
    methods: {
        onUploadRemove (upload) {
            this.$emit('remove-upload', upload._id)
        },
        loadUploads () {
            this.$emit('load-uploads')
        },
        onUploadUploaded (data) {
            const serverData = JSON.parse(data.serverId)
            if (Array.isArray(serverData) && serverData.length > 0) {
                this.$nextTick(() => {
                    this.loadUploads()
                })
            }
        },
        onCloseUploader () {
            this.$emit('close')
        }
    }
}
</script>
