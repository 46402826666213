import flat from 'flat'
import micromatch from 'micromatch'

/**
 * @typedef {{ value: string|number, text: string }} Option
 */
export class Schema {
    /**
     * Creates an instance of Schema.
     * @param {Record<string, any>} input
     * @param {{countries: Option[], personalIdTypes: Option[], genders: Option[], riskActivities: Option[], statutoryTypes: Option[]}} options
     * @memberof Schema
     */
    constructor (input, options) {
        this.schema = []
        const flatten = flat.flatten(input)
        for (const key of Object.keys(flatten)) {
            let inputType = 'text'
            let optionsData = null
            if (micromatch.isMatch(`${key}`, ['*.documentValidity', '*.birthDate'])) {
                inputType = 'date'
            }
            if (micromatch.isMatch(`${key}`, ['realOwnerUndeterminable', '*.politician', '*.noRc', 'legalInfo.socialBenefits', 'legalInfo.wage', 'legalInfo.business', 'legalInfo.business', 'legalInfo.other', 'legalInfo.sanctionedBusiness', 'legalInfo.auditionHistory', 'legalInfo.criminalActivity', '*.executive', '*.broker', '*.isStatutory', '*.isRealOwner'])) {
                inputType = 'checkbox'
            }
            const countrySelectOptions = ['*.citizenship', '*country', '*Country', '*.domicile']
            const statutoryTypeOptions = ['*.statutoryType']
            const riskActivityOptions = ['*.companyRiskActivity']
            const genderOptions = ['*.sex']
            const documentTypeOptions = ['*.documentType']
            if (micromatch.isMatch(`${key}`, [...countrySelectOptions, ...statutoryTypeOptions, ...riskActivityOptions, ...genderOptions, ...documentTypeOptions])) {
                inputType = 'select'
                if (micromatch.isMatch(`${key}`, countrySelectOptions)) {
                    optionsData = options.countries
                }
                if (micromatch.isMatch(`${key}`, statutoryTypeOptions)) {
                    optionsData = options.statutoryTypes
                }
                if (micromatch.isMatch(`${key}`, riskActivityOptions)) {
                    optionsData = options.riskActivities
                }
                if (micromatch.isMatch(`${key}`, genderOptions)) {
                    optionsData = options.genders
                    inputType = 'radio'
                }
                if (micromatch.isMatch(`${key}`, documentTypeOptions)) {
                    optionsData = options.personalIdTypes
                    inputType = 'radio'
                }
            }
            let label = key
            if (micromatch.isMatch(`${key}`, ['companyPeople.*'])) {
                label = key.replace(/((companyPeople).\d*)+/g, 'companyPeople')
            }
            if (micromatch.isMatch(`${key}`, ['realOwners.*'])) {
                label = key.replace(/((realOwners).\d*)+/g, 'realOwners')
            }
            this.schema.push({
                path: key,
                label,
                inputType,
                options: optionsData
            })
        }
    }
}
