<template>
    <div class="mb-2">
        <h4 class="mb-2">{{ $t('personalId.uploadsHeading') }}</h4>
        <div class="bg-white p-3 rounded-sm shadow">
            <p>{{ $t('dataCheckup.uploadsText') }}</p>
            <div class="row mt-4">
                <div class="col-12 col-lg-6">
                    <strong>{{ $t('personalId.chooseFiles')}}</strong>
                    <uploader-box
                        :multi="true"
                        :url="`${$api.dataCheckup.uploaderUrl}?origin=${PERSONAL_ID_ORIGIN_TYPE}`"
                        class="mt-3"
                        @uploaded="onUploadUploaded"
                    />
                </div>
                <div class="col-12 col-lg-6">
                    <strong>{{ $t('personalId.listUploadedHeading') }}</strong>
                    <p
                        class="mt-3 alert"
                        :class="($v.uploads && $v.uploads.$dirty && $v.uploads.$invalid) ? 'alert-danger' : 'alert-primary'"
                    >
                        {{ $t('dataCheckup.noDocuments')}}
                    </p>
                    <loader-overlay
                        v-if="uploads.length > 0"
                        :loading="loadingUploads"
                        class="mt-3"
                    >
                        <uploads-list
                            :uploads="uploads"
                            :removed="[]"
                            @remove="onUploadRemove"
                        />
                    </loader-overlay>
                </div>
            </div>
            <p class="mt-4 mb-0">
                <b-btn
                    @click.prevent="onCloseUploader"
                    size="sm"
                    class="mr-2"
                    :disabled="disableCancelButton"
                >
                    {{ $t('app.cancel') }}
                </b-btn>
            </p>
        </div>
    </div>
</template>
<script>
import UploaderBox from '../../../../../Components/UploaderBox.vue'
import UploadsList from '../../../../../Components/UploadsList.vue'
import { requiredIf } from 'vuelidate/lib/validators'
import { PERSONAL_ID_ORIGIN_TYPE } from './UploadOriginType'

export default {
    name: 'Uploader',
    components: {
        UploaderBox,
        UploadsList
    },
    props: {
        showUploader: {
            type: Boolean,
            required: true
        },
        disableCancelButton: {
            type: Boolean,
            required: false,
            default: false
        },
        uploads: {
            type: Array,
            required: false,
            default: () => []
        },
        loadingUploads: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.onShouldEmitIsValid()
        })
    },
    watch: {
        disableCancelButton (value) {
            this.$nextTick(() => {
                this.onShouldEmitIsValid()
            })
        },
        uploads (value) {
            this.$nextTick(() => {
                this.onShouldEmitIsValid()
            })
        }
    },
    computed: {
        PERSONAL_ID_ORIGIN_TYPE () {
            return PERSONAL_ID_ORIGIN_TYPE
        }
    },
    methods: {
        onShouldEmitIsValid () {
            this.$v.$touch()
            this.$emit('is-valid', !this.$v.$invalid)
        },
        onUploadRemove (upload) {
            this.$emit('remove-upload', upload._id)
        },
        loadUploads () {
            this.$emit('load-uploads')
        },
        onUploadUploaded (data) {
            const serverData = JSON.parse(data.serverId)
            if (Array.isArray(serverData) && serverData.length > 0) {
                this.$nextTick(() => {
                    this.loadUploads()
                })
            }
        },
        onCloseUploader () {
            if (this.uploads.length === 0 && !this.disableCancelButton) {
                this.$emit('is-valid', true)
            }
            this.$nextTick(() => {
                this.$emit('close')
            })
        }
    },
    validations: {
        uploads: {
            required: requiredIf(function (model) {
                return !this.showUploader
            }),
            custom (value) {
                return Array.isArray(value) && value.length > 0
            }
        }
    }
}
</script>
